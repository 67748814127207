@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Rima Avant Arte";
    font-weight: 400;
    font-style: normal;
    src: url("/fonts/RimaAvant-Arte-Regular.woff2") format("woff2"),
        url("/fonts/RimaAvant-Arte-Regular.woff") format("woff");
}

@font-face {
    font-family: "Rima Avant Arte";
    font-weight: 700;
    font-style: normal;
    src: url("/fonts/RimaAvant-Arte-Bold.woff2") format("woff2"), url("/fonts/RimaAvant-Arte-Bold.woff") format("woff");
}

:root {
    font-family: "Rima Avant Arte", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
        Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

*,
*:before,
*:after {
    @apply m-0 box-border p-0;
}

html,
body {
    @apply min-h-screen;
}

body {
    @apply h-full w-full text-sm tabular-nums text-brown antialiased;
}